import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    title: "Profit",
    width: "30%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_CloseButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      })
    ]),
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_el_form, {
        "label-width": "20$",
        class: "font-semibold text-2xl"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Total Count:" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data?.sellerRevenue?.orderCount) + " Orders", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Total Revenue:" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatterDollars(_ctx.data?.sellerRevenue?.totalRev)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_divider),
          _createVNode(_component_el_form_item, { label: "Total profit:" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatterDollars(_ctx.data?.sellerProfit?.totalProfit)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Total paid:" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatterDollars(_ctx.data?.sellerProfit?.totalAmountPaid)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Total Avaiable:" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatterDollars(_ctx.data?.sellerProfit?.remainProfit)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Total hold:" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatterDollars(_ctx.data?.sellerProfit?.totalHold)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_directive_loading, _ctx.isLoading]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
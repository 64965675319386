
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {},
  setup(props, { emit }) {
    const dialogVisible = ref<boolean>(false)
    const data = ref<any>(null)
    const note = ref<string>('')

    const close = () => {
      note.value = ''
      dialogVisible.value = false
    }
    const open = (item: any) => {
      data.value = item
      dialogVisible.value = true
    }

    const submit = () => {
      emit('submit', { id: data.value._id, type: 'reject', note: note.value })
      close()
    }

    return {
      dialogVisible,
      data,
      note,
      close,
      open,
      submit,
    }
  },
})

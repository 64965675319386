
import { defineComponent, ref } from 'vue'
import usePayoutsRepositories from '@/repositories/usePayoutsRepositories'
import { ElMessage } from 'element-plus/es/components'

export default defineComponent({
  components: {},
  setup(props, { emit }) {
    const dialogVisible = ref<boolean>(false)
    const sellerTeam = ref<any>()
    const note = ref<string>('')
    const { updatePayoutNote } = usePayoutsRepositories()

    const close = () => {
      note.value = ''
      dialogVisible.value = false
    }
    const open = (data: any) => {
      dialogVisible.value = true
      note.value = data.note
      sellerTeam.value = data
    }
    const submit = async () => {
      const res = (await updatePayoutNote(sellerTeam.value._id, {
        note: note.value,
      })) as any
      console.log('🚀 ~ file: EditNoteDialog.vue:41 ~ submit ~ res:', res)

      if (!res.data) {
        ElMessage.error(res.data.msg)
        close()
      } else {
        ElMessage.success('Update note successfully')
        emit('reset')
        close()
      }
    }
    return {
      dialogVisible,
      note,
      close,
      open,
      submit,
    }
  },
})

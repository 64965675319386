import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    title: "Stores",
    width: "480",
    top: "30vh"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: _ctx.stores,
        stripe: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
            return (_openBlock(), _createBlock(_component_el_table_column, {
              key: index,
              label: column.title,
              props: column.dataIndex,
              "min-width": column.minWidth
            }, {
              default: _withCtx((item) => [
                (column.dataIndex == 'name')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.row.name), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex == 'link')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                      _createElementVNode("a", {
                        href: _ctx.redirectLink(item),
                        target: "_blank"
                      }, _toDisplayString(item.row.appShopUrl), 9, _hoisted_3)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["label", "props", "min-width"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}

import { defineComponent, ref } from 'vue'
import axios from '@/services/axios'

export default defineComponent({
  components: {},
  setup() {
    const dialogVisible = ref<boolean>(false)
    const baseResource = 'shops'

    const columns = [
      {
        key: '_id',
        title: 'Name',
        dataIndex: 'name',
        minWidth: '10',
      },
      {
        title: 'Link',
        dataIndex: 'link',
        minWidth: '20',
      },
    ]
    const params = ref<any>({
      sellerTeam: '',
    })
    const stores = ref([])

    const close = () => {
      stores.value = []
      dialogVisible.value = false
    }

    const open = async (data: any) => {
      params.value.sellerTeam = data?.sellerTeam.id
      const res = await axios.get(`${baseResource}`, { params })
      stores.value = res.data.data

      dialogVisible.value = true
    }

    const redirectLink = (item: any) => {
      return `https://${item.row.appShopUrl}`
    }

    return {
      dialogVisible,
      columns,
      stores,
      close,
      open,
      redirectLink,
    }
  },
})

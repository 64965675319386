
import { computed, defineComponent, reactive, ref, onMounted, watch } from 'vue'
import { formatTime } from '@/utils/dateTime'
import { useFetchData } from '@/composables'
import usePayoutsRepositories from '@/repositories/usePayoutsRepositories'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { ElMessage } from 'element-plus/es/components'
import { MoreFilled } from '@element-plus/icons'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import ProfitDialog from './components/ProfitDialog.vue'
import ApproveDialog from './components/ApproveDialog.vue'
import EditNoteDialog from './components/EditNoteDialog.vue'
import RejectDialog from './components/RejectDialog.vue'
import StoresDialog from './components/StoresDialog.vue'
import SelectSellerTeam from '@/components/common/SelectSellerTeam.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    Pagination,
    ProfitDialog,
    EditNoteDialog,
    ApproveDialog,
    MoreFilled,
    RejectDialog,
    StoresDialog,
    SelectSellerTeam,
  },
  setup() {
    const breadcrumbs = ['Payouts', '']
    const title = 'Payouts'
    const baseResource = 'payouts'

    let loading = ref(false)
    const columns = [
      {
        key: '_id',
        title: 'Name',
        dataIndex: 'name',
        minWidth: '10',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        minWidth: '6',
      },
      {
        title: 'Method',
        dataIndex: 'payoutMethod',
        minWidth: '10',
      },
      {
        title: 'Account',
        dataIndex: '_account',
        minWidth: '12',
      },
      {
        title: 'Created At',
        dataIndex: 'createdDate',
        minWidth: '11',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        minWidth: '8',
      },
      {
        title: 'BillName',
        dataIndex: 'billName',
        minWidth: '10',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        minWidth: '8',
      },
      {
        title: 'Approved At',
        dataIndex: 'approvedDate',
        minWidth: '11',
      },
      {
        title: 'Note',
        dataIndex: 'note',
        minWidth: '10',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        minWidth: '8',
        align: 'center',
      },
    ]
    const data = reactive({
      status: [
        {
          id: 0,
          name: 'Pending',
        },
        {
          id: 1,
          name: 'Approved',
        },
        {
          id: 2,
          name: 'Rejected',
        },
      ],
    })
    const params = reactive({
      sellerTeamId: null,
      billName: null,
      status: null,
      page: 1,
      limit: 10,
    })

    const profitDialog = ref<InstanceType<typeof ProfitDialog>>()
    const approveDialog = ref<InstanceType<typeof ApproveDialog>>()
    const rejectDialog = ref<InstanceType<typeof RejectDialog>>()
    const editNoteDialog = ref<InstanceType<typeof EditNoteDialog>>()
    const storesDialog = ref<InstanceType<typeof StoresDialog>>()

    const { updatePayout } = usePayoutsRepositories()

    const content = computed(() => {
      return { breadcrumbs, title }
    })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )

    onMounted(async () => {
      loading = isLoading
      await fetchData()
    })

    watch(params, fetchData)

    const onFilterCallback = (filter: any) => {
      params.billName = filter.search
    }

    const methodName = (val: string) => {
      if (val === 'paypal') return 'PayPal'
      if (val === 'pingpong') return 'PingPong'
      if (val === 'creditcard') return 'Credit card'
      if (val === 'payoneer') return 'Payoneer'
      return val
    }
    const statusName = (val: any) => {
      if (val === 0) return 'Pending'
      if (val === 1) return 'Approved'
      if (val === 2) return 'Rejected'
      return 'n/a'
    }

    const openProfitDialog = (item: any) => {
      profitDialog.value?.open(item)
    }

    const openApproveDialog = (item: any) => {
      approveDialog.value?.open(item)
    }

    const openRejectDialog = (item: any) => {
      rejectDialog.value?.open(item)
    }

    const openEditNoteDialog = (data: string) => {
      editNoteDialog.value?.open(data)
    }

    const openStoresDialog = (data: any) => {
      storesDialog.value?.open(data)
    }

    const setStatus = async (item: any) => {
      console.log('🚀 ~ file: index.vue:312 ~ setStatus ~ item:', item)
      const st = item.type === 'approve' ? 1 : 2
      const res = (await updatePayout(item.id, {
        note: item.note,
        status: st,
      })) as any

      if (!res.data.success) {
        ElMessage.error(res.data.msg)
      } else {
        ElMessage.success('Update note successfully')
        await fetchData()
      }
    }
    const onChangeCardNumber = (number: string) => {
      const value = number
        .replace(/[^0-9]/gi, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
      return value
    }

    return {
      content,
      data,
      params,
      isLoading,
      documents,
      loading,
      columns,
      total,
      profitDialog,
      approveDialog,
      rejectDialog,
      editNoteDialog,
      storesDialog,
      formatTime,
      methodName,
      statusName,
      onFilterCallback,
      openProfitDialog,
      openApproveDialog,
      openRejectDialog,
      openEditNoteDialog,
      openStoresDialog,
      setStatus,
      fetchData,
      onChangeCardNumber,
    }
  },
})

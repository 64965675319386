
import { defineComponent, ref } from 'vue'
import useProfitsRepositories from '@/repositories/useProfitsRepositories'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { formatterDollars } from '@/utils/formatter'
import CloseButton from '@/components/common/buttons/CloseButton.vue'

export default defineComponent({
  components: { CloseButton },
  setup() {
    const dialogVisible = ref<boolean>(false)
    const { getProfitsAgency, getAvailableProfits } = useProfitsRepositories()
    const { getTotalRevSellerTeam } = useOrderRepositories()
    const data = ref({})
    const isLoading = ref(true)
    const close = () => {
      dialogVisible.value = false
    }
    const open = async (item: any) => {
      dialogVisible.value = true
      isLoading.value = true

      const sellerTeamId: string = item.row.sellerTeam.id
      const p1 = getProfitsAgency(sellerTeamId)
      const p2 = getAvailableProfits(sellerTeamId)
      const p3 = getTotalRevSellerTeam(sellerTeamId)
      let [resProfits, resRemainProfit, revenue] = await Promise.all([p1, p2, p3])

      let sellerProfit = { ...(resProfits?.data[0] || {}), remainProfit: resRemainProfit?.data.remainProfit || 0 }
      let sellerRevenue = { ...revenue.data }
      data.value = { sellerProfit, sellerRevenue }

      isLoading.value = false
    }

    return { isLoading, dialogVisible, data, close, open, formatterDollars }
  },
})

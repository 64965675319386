
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {},

  setup(props, { emit }) {
    const dialogVisible = ref<boolean>(false)
    const data = ref<any>(null)
    const note = ref<string>('')

    const close = () => {
      dialogVisible.value = false
    }
    const open = (item: any) => {
      data.value = item
      dialogVisible.value = true
    }

    const submit = () => {
      emit('submit', { id: data.value._id, type: 'approve', note: note.value })
      close()
    }

    const methodName = (val: string) => {
      console.log('🚀 ~ file: index.vue:249 ~ methodName ~ val:', val)
      if (val === 'paypal') return 'PayPal'
      if (val === 'pingpong') return 'PingPong'
      if (val === 'creditcard') return 'Credit card'
      if (val === 'payoneer') return 'Payoneer'
      return val
    }

    return {
      dialogVisible,
      data,
      note,
      close,
      open,
      submit,
      methodName,
    }
  },
})
